import React from "react"
import { css } from "@emotion/core"
import GoogleMapReact from "google-map-react"

import Image from "./Image"
import mapStyle from "../../utils/mapStyle"

import MarkerImage from "../../images/map/marker.svg"

const Marker = () => (
  <Image
    css={css`
      transform-origin: center center;
      transform: translateX(-35px) translateY(-35px);
      width: 70px;
      height: 70px;
    `}
    src={MarkerImage}
  />
)

const Map = ({
  lat = 47.49453652176922,
  lng = 19.05193349590184,
  zoom = 17,
}) => (
  <div
    css={css`
      height: 640px;
    `}
  >
    <GoogleMapReact
      options={{
        styles: mapStyle,
      }}
      bootstrapURLKeys={{ key: process.env.GATSBY_MAPS_API_KEY }}
      defaultCenter={{ lat, lng }}
      defaultZoom={zoom}
    >
      <Marker lat={lat} lng={lng} />
    </GoogleMapReact>
  </div>
)

export default Map
