import React, { useState } from "react"
import { css, keyframes } from "@emotion/core"
import Image from "./Image"

import ArrowImage from "../../images/common/select_arrow.png"

const Input = ({
  name,
  placeholder,
  type,
  value,
  onChange,
  light,
  ...rest
}) => {
  const [active, setActive] = useState(false)
  const [shouldMoveDown, setShouldMoveDown] = useState(false)

  const moveUp = keyframes`
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(-30px);
    }
  `
  const moveDown = keyframes`
    from {
      transform: translateY(-30px);
    }

    to {
      transform: translateY(0px);
    }
  `

  const moveIn = keyframes`
    0% {
      transform: translateY(-25%) scale(0.5) translateX(-10px);
    }
    50% {
      transform: translateY(-25%) scale(0.5) translateX(0px);
    }
  `

  const widen = keyframes`
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  `

  return (
    <div
      css={css`
        position: relative;
        margin-bottom: 50px;

        &:hover {
          & > label > span {
            opacity: 1;
          }

          & > label > img {
            opacity: 1;
            animation: ${moveIn} 0.5s linear;
            animation-iteration-count: 1;
          }
        }
      `}
      onFocus={() => {
        setActive(true)
        setShouldMoveDown(false)
      }}
      onBlur={() => {
        setActive(false)
        setShouldMoveDown(true)
      }}
    >
      <label htmlFor={name}>
        <Image
          src={ArrowImage}
          css={css`
            opacity: ${active ? 1 : 0};
            position: absolute;
            top: 0px;
            left: -36px;
            transform: translateY(-25%) scale(0.5);
            transition: 0.5s;
          `}
        />
        <input
          id={name}
          type={type}
          value={value}
          onChange={onChange}
          css={theme => css`
            outline: 0;
            width: 100%;
            height: 35px;
            border: 0px;
            color: ${light ? theme.colors.darkGray : theme.colors.white};
            background: transparent;
            background-image: linear-gradient(
              45deg,
              ${theme.colors.darkGray} 0%,
              ${theme.colors.darkGray} 100%
            );
            background-repeat: no-repeat;
            background-position-y: bottom;
            background-size: 100% 2px;
            font-size: ${theme.constants.xxxSmallFontSize}px;
            font-weight: ${theme.constants.demi};

            opacity: ${value ? "1" : "0.4"};
            padding-left: 20px;

            transition: 0.5s;

            &:hover {
              opacity: 1;
            }

            &:focus {
              opacity: 1;
              ${theme.gradients.blue(theme)};
              background-repeat: no-repeat;
              background-position-y: bottom;
              background-size: 100% 2px;
              animation: ${widen} 0.5s;
            }
          `}
          {...rest}
        />
        <span
          css={theme => css`
            font-size: ${theme.constants.xxxSmallFontSize}px;
            font-weight: ${theme.constants.demi};
            position: absolute;
            padding-top: 10px;
            height: 35px;
            padding-left: 20px;
            ${light && `color: ${theme.colors.darkGray};`}
            transform-origin: center center;
            width: 100%;
            top: 0;
            left: 0;
            opacity: 0.4;
            transition: 0.5s;

            &:hover {
              opacity: 1;
            }

            animation: ${(active || value) && moveUp} 0.5s ease,
              ${shouldMoveDown && !value && moveDown} 0.5s ease;
            animation-fill-mode: forwards;
            animation-iteration-count: 1;
          `}
        >
          {placeholder.toUpperCase()}
        </span>
      </label>
    </div>
  )
}
export default Input
