import React from "react"
import { css } from "@emotion/core"

const Option = ({ value, onChange, setActive, light }) => (
  <div
    role="option"
    aria-selected={false}
    tabIndex={0}
    onKeyPress={() => {
      onChange({ target: { value } })
      setActive(false)
    }}
    onClick={() => {
      onChange({ target: { value } })
      setActive(false)
    }}
    css={theme => css`
      padding: 10px 0;
      color: ${light ? theme.colors.veryDarkgray : theme.colors.white};
      cursor: pointer;
      &:hover {
        color: ${theme.colors.blue};
      }

      &:focus {
        color: ${theme.colors.blue};
      }
    `}
  >
    {value}
  </div>
)

export default Option
