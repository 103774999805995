import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import ExternalLink from "./ExternalLink"

const style = ({ theme }) =>
  css`
    color: ${theme.colors.white};
    font-size: ${theme.constants.buttonTextSize}px;
    background: rgba(44, 181, 252, 0.5);
    padding: 18px;
    transition: 0.5s ease-in-out;
    text-decoration: none;

    border-radius: 25px;
    border: 2px solid ${theme.colors.white};

    &:hover {
      background: transparent;
      color: ${theme.colors.white};
    }

    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;

    @media (max-width: 600px) {
      font-size: ${theme.constants.smallButtonTextSize}px;
      height: 30px;
    }
  `

const StyledLink = styled(ExternalLink)`
  ${style}
`

const OutlineExternalLink = props => <StyledLink {...props} />

export default OutlineExternalLink
