import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import LinkButton from "./LinkButton"

const style = ({ theme }) =>
  css`
    color: ${theme.colors.white};
    font-size: ${theme.constants.buttonTextSize}px;
    background: ${theme.colors.transparentWhite};
    padding: 18px;
    transition: 0.5s ease-in-out;

    border-radius: 25px;
    border: 2px solid ${theme.colors.white};

    &:hover {
      background: transparent;
      color: ${theme.colors.white};
    }
  `

const StyledButton = styled(LinkButton)`
  ${style}
`

const OutlineLinkButton = props => (
  <StyledButton {...props} arrowVariant="white" hoverArrowVariant="white" />
)

export default OutlineLinkButton
