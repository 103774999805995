import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import HeaderLink from "./HeaderLink"

const style = ({ theme }) => css`
  font-size: ${theme.constants.mediumFontSize}px;
  height: 50px;

  @media (max-width: 880px) {
    transform: translateX(-20px);
  }
`

const StyledHeaderLink = styled(HeaderLink)`
  ${style}
`

const MobileHeaderLink = props => <StyledHeaderLink {...props} />

export default MobileHeaderLink
