import React, { useState } from "react"
import { css } from "@emotion/core"
import { withTheme } from "emotion-theming"
import { isBrowser } from "../utils/isBrowser"
import { pathNameToTitle } from "../utils/pathNameToTitle"

import { useWindowPosition } from "../hooks/useWindowPosition"
import { useWindowWidth } from "../hooks/useWindowWidth"
import {
  Image,
  HeaderLink,
  MenuButton,
  MobileHeaderLink,
  CloseButton,
  Link,
} from "./common"

import DeverestLogo from "../images/common/logo_regular.svg"
import HeroDeverestLogo from "../images/common/logo_white_text.svg"
import SimpleDeverestLogo from "../images/common/simple_logo.svg"

const transparent = (hero, position, threshold) => {
  const y = position >= 0 ? position : 0
  if (!hero) {
    return false
  }
  if (hero && y < threshold) return true
  return false
}

const Header = ({ hero, threshold = 10 }) => {
  const [showMenu, setShowMenu] = useState(false)
  const position = useWindowPosition()
  const width = useWindowWidth()

  const closeMenu = () => setShowMenu(false)

  return (
    <header
      css={theme => css`
        top: 0;
        position: sticky;
        left: 0;
        right: 0;
        width: 100%;
        transform: translate3d(0, 0, 0);

        z-index: 1000;
        background: ${transparent(hero, position, 10)
          ? "transparent"
          : theme.colors.white};
        height: ${theme.constants.headerHeight +
          theme.constants.headerBottomPadding}px;
        margin-bottom: ${theme.constants.headerBottomMargin}px;
      `}
    >
      <div
        css={theme => css`
          height: ${theme.constants.headerHeight}px;
          margin: ${theme.constants.pageMargin};
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 30px;
          margin-bottom: ${theme.constants.headerBottomPadding}px;

          @media (max-width: 1200px) {
            margin: ${theme.constants.mediumpageMargin};
          }

          @media (max-width: 880px) {
            margin: ${theme.constants.smallpageMargin};
          }
        `}
      >
        {width >= 880 && (
          <>
            <Link to="/">
              <Image
                src={
                  transparent(hero, position, threshold)
                    ? HeroDeverestLogo
                    : DeverestLogo
                }
                alt="logo"
                css={css`
                  height: 40px;
                `}
              />
            </Link>
            <div
              css={css`
                width: 600px;
                display: flex;
                align-items: center;
                justify-content: space-between;
              `}
            >
              <HeaderLink
                hero={transparent(hero, position, threshold)}
                to="/about"
              >
                About
              </HeaderLink>
              <HeaderLink
                hero={transparent(hero, position, threshold)}
                to="/services"
              >
                Services
              </HeaderLink>
              <HeaderLink
                hero={transparent(hero, position, threshold)}
                to="/projects"
              >
                Projects
              </HeaderLink>
              <HeaderLink
                hero={transparent(hero, position, threshold)}
                to="/career"
              >
                Career
              </HeaderLink>
              <HeaderLink
                hero={transparent(hero, position, threshold)}
                to="/contact"
              >
                Contact
              </HeaderLink>
            </div>
          </>
        )}
        {width < 880 && (
          <>
            <Link
              to="/"
              css={css`
                z-index: 1000;
              `}
            >
              <Image
                src={
                  // eslint-disable-next-line no-nested-ternary
                  isBrowser() && window.location.pathname === "/"
                    ? transparent(hero, position, threshold)
                      ? HeroDeverestLogo
                      : DeverestLogo
                    : SimpleDeverestLogo
                }
                alt="logo"
                css={css`
                  height: 40px;
                `}
              />
            </Link>
            {isBrowser() && window.location.pathname !== "/" && (
              <p
                css={theme => css`
                  font-size: ${theme.constants.mediumFontSize}px;
                  font-weight: ${theme.constants.demi};
                  color: ${transparent(hero, position, threshold)
                    ? theme.colors.white
                    : theme.colors.veryDarkgray};
                  @media (max-width: 360px) {
                    font-size: ${theme.constants.smallFontSize}px;
                  }
                `}
              >
                {pathNameToTitle(window.location.pathname)}
              </p>
            )}
            {!showMenu && (
              <MenuButton
                white={transparent(hero, position, threshold)}
                onClick={() => setShowMenu(true)}
              />
            )}
            {showMenu && <CloseButton onClick={closeMenu} />}
          </>
        )}
      </div>
      {width < 880 && showMenu && (
        <div
          css={theme => css`
            background: ${theme.colors.white};
            position: absolute;
            height: 100vh;
            width: 100vw;
            top: 0;
            left: 0;
            z-index: 100;
          `}
        >
          <div
            css={theme => css`
              margin-top: ${theme.constants.headerHeight}px;
              height: calc(100vh - ${theme.constants.headerHeight}px);
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
              align-items: center;
            `}
          >
            <MobileHeaderLink onClick={closeMenu} to="/about">
              About
            </MobileHeaderLink>
            <MobileHeaderLink onClick={closeMenu} to="/services">
              Services
            </MobileHeaderLink>
            <MobileHeaderLink onClick={closeMenu} to="/projects">
              Projects
            </MobileHeaderLink>
            <MobileHeaderLink onClick={closeMenu} to="/career">
              Career
            </MobileHeaderLink>
            <MobileHeaderLink onClick={closeMenu} to="/contact">
              Contact
            </MobileHeaderLink>
          </div>
        </div>
      )}
    </header>
  )
}

export default withTheme(Header)
