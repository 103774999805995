import React from "react"
import { css } from "@emotion/core"
import GradientCircleLinkImage from "./GradientCircleLinkImage"

const SocialColumn = ({ image, to, title, subText, link }) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      line-height: 1.5;
      margin-bottom: 50px;

      @media (max-width: 880px) {
        flex-direction: row;
        justify-content: inherit;
      }
    `}
  >
    <GradientCircleLinkImage size={50} src={image} to={to} />
    <div
      css={css`
        text-align: center;

        @media (max-width: 880px) {
          text-align: left;
          margin-left: 50px;
        }

        @media (max-width: 360px) {
          margin-left: 20px;
        }
      `}
    >
      <div
        css={theme => css`
          margin-top: 30px;
          font-weight: ${theme.constants.demi};
          font-size: ${theme.constants.smallFontSize}px;
          @media (max-width: 880px) {
            margin-top: 0px;
          }
        `}
      >
        {title}
      </div>
      <div
        css={theme => css`
          font-size: ${theme.constants.extraSmallFontSize}px;
        `}
      >
        {subText}
      </div>
      <a
        href={to}
        target="_blank"
        rel="noreferrer noopener"
        css={theme => css`
          font-size: ${theme.constants.extraSmallFontSize}px;
          font-weight: ${theme.constants.medium};
          color: ${theme.colors.blue};
          text-decoration: none;
          transition: 0.5s;

          &:hover {
            color: ${theme.colors.darkBlue};
          }
        `}
      >
        {link}
      </a>
    </div>
  </div>
)

export default SocialColumn
