import React from "react"
import { css } from "@emotion/core"

const Image = ({ src, className, alt, ...rest }) => (
  <img
    src={src}
    css={css`
      user-select: none;
    `}
    className={className}
    alt={alt}
    {...rest}
  />
)

export default Image
