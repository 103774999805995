import { useEffect, useState } from "react"
import { isBrowser } from "../utils/isBrowser"

export const useWindowWidth = () => {
  const [width, setWidth] = useState()

  useEffect(() => {
    setWidth(isBrowser() && window.innerWidth)
    const handleResize = () => setWidth(isBrowser() && window.innerWidth)
    if (isBrowser()) {
      window.addEventListener("resize", handleResize)
    }

    return () =>
      isBrowser() && window.removeEventListener("resize", handleResize)
  }, [])

  return width
}
