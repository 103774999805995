import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Button from "./Button"

const style = ({ theme }) =>
  css`
    color: ${theme.colors.white};
    font-size: ${theme.constants.buttonTextSize}px;
    ${theme.gradients.blue(theme)};
    padding: 18px;

    border-radius: 25px;

    &:hover {
      opacity: 0;
    }
  `

const styleHover = ({ theme }) =>
  css`
    color: ${theme.colors.white};
    font-size: ${theme.constants.buttonTextSize}px;
    background: ${theme.colors.darkBlue};
    padding: 18px;
    position: absolute;
    top: 0;
    z-index: 100;
    opacity: 0;

    border-radius: 25px;
    transition: 0.5s;

    &:hover {
      background: ${theme.colors.darkBlue};
      color: ${theme.colors.white};
      opacity: 1;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  `

const StyledButton = styled(Button)`
  ${style}
`

const StyledHoverButton = styled(Button)`
  ${styleHover}
`

const GradientButton = props => (
  <div
    css={css`
      position: relative;
    `}
  >
    <StyledButton {...props} arrowVariant="white" hoverArrowVariant="white" />
    <StyledHoverButton
      {...props}
      arrowVariant="white"
      hoverArrowVariant="white"
    />
  </div>
)

export default GradientButton
