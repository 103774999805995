import React, { useCallback, useState } from "react"
import { css } from "@emotion/core"
import { useDropzone } from "react-dropzone"

import RightArrow from "./RightArrow"

const File = ({ name, placeholder, light, onFile, onName, fileName }) => {
  const [active, setActive] = useState()

  const onDrop = useCallback(
    async acceptedFiles => {
      const file = acceptedFiles[0]
      if (file.name) {
        onName(file.name)
      }
      onFile(file)
    },
    [onFile, onName]
  )
  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <div
      css={css`
        position: relative;
        margin-bottom: 60px;

        @media (max-width: 1200px) {
          margin-bottom: 65px;
        }

        &:hover {
          & > label > span > p {
            opacity: 1;
          }
        }
      `}
      onFocus={() => {
        setActive(true)
      }}
      onBlur={() => {
        setActive(false)
      }}
    >
      <label htmlFor={name} {...getRootProps()}>
        <input
          id={name}
          css={css`
            display: none;
            width: 100%;
            height: 35px;
          `}
          {...getInputProps({
            accept: ".pdf",
          })}
        />
        <span
          css={theme => css`
            font-size: ${theme.constants.xxxSmallFontSize}px;
            font-weight: ${theme.constants.demi};
            border: ${active ? 3 : 2}px solid
              ${active ? theme.colors.darkBlue : theme.colors.darkGray};
            position: absolute;
            height: 50px;
            padding-left: 20px;
            padding-right: 35px;
            ${light && `color: ${theme.colors.darkGray};`}
            transform-origin: center center;
            width: 100%;
            top: 0;
            left: 0;
            opacity: ${fileName ? 1 : 0.4};
            display: flex;
            align-items: center;
            transform: translateY(-15px);
            transition: 0.5s;

            &:hover {
              opacity: 1;
            }

            &:focus {
              opacity: 1;
            }
          `}
        >
          <p
            css={css`
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
            `}
          >
            {!fileName ? placeholder.toUpperCase() : fileName}
          </p>
          <RightArrow
            rotate={-180}
            variant={active ? "darkblue" : "gray"}
            css={css`
              position: absolute;
              right: 0;

              margin-right: 8px;
            `}
          />
        </span>
      </label>
    </div>
  )
}
export default File
