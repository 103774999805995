export default {
  colors: {
    white: "white",
    black: "black",
    gray: "rgba(233, 233, 233, 1)",
    darkGray: "rgba(127, 128, 141,1)",
    veryDarkgray: "rgba(36, 37, 45, 1)",
    dropdownDarkBackground: "rgba(72, 73, 83, 1)",
    dropdownLightBackground: "rgba(242,242,243, 1)",
    blue: "rgba(44, 181, 252, 1)",
    darkBlue: "rgba(0, 95, 232, 1)",
    transparentWhite: "rgba(255, 255, 255, 0.2)",
  },
  gradients: {
    blue: theme =>
      `background-image: linear-gradient(
        45deg,
        ${theme.colors.darkBlue} 0%,
        ${theme.colors.blue} 100%
      );`,
    blueSpinner: (theme, hide) =>
      `background-image: linear-gradient(
        45deg,
        ${theme.colors.darkBlue} 0%,
        ${theme.colors.darkBlue} 50%,
        ${hide} 70%,
        transparent 100%
      );`,
  },
  constants: {
    headerHeight: 80,
    headerTextSize: 14,
    headerBottomPadding: 20,
    headerBottomMargin: 30,
    heroHeight: 800,
    mediumheroHeight: 600,
    heroTitleSize: 60,
    heroDescriptionSize: 30,
    buttonTextSize: 14,
    smallButtonTextSize: 12,
    footerHeight: 140,
    infoHeight: 500,
    pageMargin: "0px 165px 0px 150px",
    mediumpageMargin: "0px 50px 0px 50px",
    smallpageMargin: "0px 20px 0px 20px",
    footerTextSize: 12,
    infoTitleTextSize: 30,
    infoDescriptionTextSize: 16,
    infoFullHeight: 700,
    contactBoxTitleSize: 30,
    contactBoxDescriptionSize: 24,
    aboutBoxDescriptionSize: 20,
    largeFontSize: 60,
    semiFontSize: 50,
    largerFontSize: 40,
    mediumFontSize: 30,
    smallFontSize: 20,
    extraSmallFontSize: 14,
    xxxSmallFontSize: 12,
    medium: 300,
    light: 200,
    extraLight: 100,
    demi: 500,
    bold: 700,
    normal: 400,
    boxShadow: "0px 40px 50px -30px rgba(127, 128, 141, 1)",
    aboutBoxHeight: 840,
  },
}
