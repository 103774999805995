import React from "react"
import { css } from "@emotion/core"
import Image from "./Image"

import DoggoIcon from "../../images/common/doggo_icon.svg"

const icons = [DoggoIcon, DoggoIcon, DoggoIcon]

const IconRow = () => (
  <div
    css={css`
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    `}
  >
    {icons.map((icon, i) => (
      <Image
        css={css`
          @media (max-width: 1000px) {
            margin: 30px;
          }
          @media (max-width: 600px) {
            margin: 20px;
          }
        `}
        // eslint-disable-next-line react/no-array-index-key
        key={i}
        src={icon}
      />
    ))}
  </div>
)

export default IconRow
