import React from "react"
import { css } from "@emotion/core"
import SocialColumn from "./SocialColumn"

import DarkLinkedinLogo from "../../images/common/linkedin_dark.svg"
import DarkFacebookLogo from "../../images/common/facebook_dark.svg"
import DarkInstagramLogo from "../../images/common/instagram_dark.svg"

const SocialGrid = () => (
  <div
    css={theme => css`
      padding: ${theme.constants.pageMargin};
      margin-top: 100px;

      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      grid-auto-rows: auto;
      align-items: center;
      grid-column-gap: 140px;
      grid-row-gap: 0px;
      width: 100%;

      @media (max-width: 1200px) {
        padding: ${theme.constants.mediumpageMargin};
        grid-column-gap: 50px;
      }

      @media (max-width: 1000px) {
        margin-top: 50px;
      }

      @media (max-width: 880px) {
        padding: ${theme.constants.smallpageMargin};
        grid-template-columns: 1fr;
        justify-content: left;
        width: min-content;
        margin-bottom: 0px;
      }

      @media (max-width: 360px) {
        padding: 0;
        margin: 0 auto;
        margin-top: 50px;
      }
    `}
  >
    <SocialColumn
      image={DarkLinkedinLogo}
      to="https://www.linkedin.com/company/deverest-development"
      title="LinkedIn"
      subText="Follow us on LinkedIn"
      link="Deverest Software"
    />
    <SocialColumn
      image={DarkFacebookLogo}
      to="https://www.facebook.com/deverestteam"
      title="Facebook"
      subText="Like us on Facebook"
      link="@deverestteam"
    />
    <SocialColumn
      image={DarkInstagramLogo}
      to="https://www.instagram.com/deverestdevelopment"
      title="Instagram"
      subText="Follow us on Instagram"
      link="@deverestdevelopment"
    />
  </div>
)

export default SocialGrid
