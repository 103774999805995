import React from "react"
import { css } from "@emotion/core"

const HeroHeader = ({ heroContent: HeroContent, heroImage }) => (
  <div
    css={theme =>
      css`
        background: url(${heroImage});
        height: ${theme.constants.heroHeight}px;
        background-size: cover;
        background-position-x: center;
        background-position-y: top;
        background-repeat: no-repeat;

        position: inherit;
        top: -${theme.constants.headerHeight + theme.constants.headerBottomMargin + theme.constants.headerBottomPadding}px;

        @media (max-width: 1000px) {
          height: ${theme.constants.mediumheroHeight}px;
        }
      `
    }
  >
    <HeroContent />
  </div>
)

export default HeroHeader
