import React from "react"
import { css } from "@emotion/core"
import Image from "./Image"

const GradientCircleImage = ({
  className,
  size = 100,
  src,
  border = 3,
  fillColor = "white",
}) => (
  <div
    className={className}
    css={theme => css`
      width: ${size}px;
      height: ${size}px;
      ${theme.gradients.blue(theme)};
      display: flex;
      align-items: center;
      justify-content: center;
      padding: ${border}px;

      cursor: pointer;
      transition: 0.5s ease-in-out;

      border-radius: 50%;
    `}
  >
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        background: ${fillColor};
      `}
    >
      <Image
        css={css`
          height: 33%;
        `}
        src={src}
      />
    </div>
  </div>
)
export default GradientCircleImage
