import React from "react"
import { css } from "@emotion/core"

import { useWindowWidth } from "../../hooks/useWindowWidth"

import GradientLinkButton from "./GradientLinkButton"

import ShyImage from "../../images/infobox/shy.jpg"

const ContactBox = () => {
  const width = useWindowWidth()

  return (
    <div
      css={css`
        background: url(${ShyImage});
        background-size: cover;
        background-position-x: center;
        background-position-y: top;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @media (max-width: 1000px) {
          padding: 50px;
        }

        @media (max-width: 880px) {
          padding: 50px 20px;
        }

        @media (max-width: 600px) {
          padding-top: 160px;
          padding-bottom: 160px;
        }
      `}
    >
      <div
        css={theme => css`
          font-size: ${theme.constants.contactBoxTitleSize}px;
          font-weight: ${theme.constants.demi};
          color: ${theme.colors.veryDarkgray};
          margin-bottom: 30px;
          text-align: center;
        `}
      >
        Don’t be shy - say hi!
      </div>
      <div
        css={theme => css`
          font-size: ${theme.constants.contactBoxDescriptionSize}px;
          font-weight: ${theme.constants.light};
          color: ${theme.colors.veryDarkgray};
          text-align: center;

          margin: 0 128px;
          margin-bottom: 16px;

          @media (max-width: 880px) {
            margin: 0;
            margin-bottom: 16px;
          }
        `}
      >
        Really. We are approachable and always on the lookout for a new
        adventure!
      </div>
      <GradientLinkButton
        centered={width <= 880}
        css={css`
          width: 300px;

          @media (max-width: 880px) {
            width: 100%;
          }
        `}
        to="/contact"
      >
        send us a message
      </GradientLinkButton>
    </div>
  )
}

export default ContactBox
