import React from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const ExternalLink = ({ to, className, children }) => (
  <OutboundLink
    href={to}
    target="_blank"
    rel="noreferrer noopener"
    className={className}
  >
    {children}
  </OutboundLink>
)

export default ExternalLink
