import React from "react"
import { css } from "@emotion/core"

import Image from "./Image"

import MenuButtonImage from "../../images/common/menu_button.svg"
import WhiteMenuButtonImage from "../../images/common/menu_button_white.svg"

const MenuButton = ({ onClick, className, white, ...rest }) => (
  <button
    onClick={onClick}
    className={className}
    css={theme => css`
      text-decoration: none;
      display: flex;
      align-items: center;
      height: 40px;
      width: 40px;
      padding: 10px;
      background: transparent;
      cursor: pointer;

      color: ${theme.colors.blue};
    `}
    {...rest}
  >
    <Image
      src={white ? WhiteMenuButtonImage : MenuButtonImage}
      alt="menu"
      css={css`
        height: 100%;
        width: 100%;
      `}
    />
  </button>
)

export default MenuButton
