import React from "react"
import { css } from "@emotion/core"
import { ThemeProvider } from "emotion-theming"
import { Helmet } from "react-helmet"

import { ContactBox } from "./common"

import { useSiteMetadata } from "../hooks/useSiteMetadata"

import theme from "../utils/theme"

import SEO from "./Seo"
import Header from "./Header"
import HeroHeader from "./HeroHeader"
import Footer from "./Footer"
import Info from "./Info"
import "./Layout.css"

const Layout = ({
  children,
  withHero,
  heroContent,
  heroImage,
  infoContent: InfoContent = ContactBox,
}) => {
  const { title } = useSiteMetadata()

  return (
    <ThemeProvider theme={theme}>
      <SEO />
      <Helmet>
        <title>{title}</title>
        <script type="text/javascript">{`
          window.$crisp=[];window.CRISP_WEBSITE_ID="0fa56639-638a-4fb2-95f6-a4d8ad62f220";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();
        `}</script>
      </Helmet>
      <div
        css={theme => css`
          min-height: 100vh;
          background: ${theme.colors.white};
          position: relative;
          max-width: 1920px;
          margin: 0 auto;
        `}
      >
        <Header hero={withHero} />
        {withHero && (
          <HeroHeader heroContent={heroContent} heroImage={heroImage} />
        )}
        <div>{children}</div>
        <Info>
          <InfoContent />
        </Info>
        <Footer />
      </div>
    </ThemeProvider>
  )
}

export default Layout
