import React from "react"
import { css } from "@emotion/core"
import Image from "./Image"

import ArrowImage from "../../images/common/arrow_down_white.svg"
import BlueArrowImage from "../../images/common/arrow_down_blue.svg"
import DarkBlueArrowImage from "../../images/common/arrow_down_darkblue.svg"
import GrayArrowImage from "../../images/common/arrow_down_gray.svg"

const arrowSwitch = variant => {
  switch (variant) {
    case "blue":
      return BlueArrowImage
    case "darkblue":
      return DarkBlueArrowImage
    case "gray":
      return GrayArrowImage
    case "white":
      return ArrowImage
    default:
      return ArrowImage
  }
}

const RightArrow = ({
  width = 19,
  height = 12,
  variant,
  rotate = -90,
  className,
}) => (
  <div
    css={css`
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: ${width}px;
      height: ${height}px;
    `}
    className={className}
  >
    <Image
      src={arrowSwitch(variant)}
      css={css`
        transform: rotate(${rotate}deg);
        width: ${height}px;
        height: ${width}px;
      `}
    />
  </div>
)

export default RightArrow
