import React from "react"
import { css } from "@emotion/core"
import Image from "./Image"

const GradientCircleLinkImage = ({
  to,
  className,
  size = 100,
  src,
  border = 2,
  fillColor = "rgb(232, 244, 254)",
  hoverFillColor = "white",
}) => (
  <a href={to} target="_blank" rel="noreferrer noopener">
    <div
      className={className}
      css={theme => css`
        width: ${size}px;
        height: ${size}px;
        ${theme.gradients.blue(theme)};
        display: flex;
        align-items: center;
        justify-content: center;
        padding: ${border}px;

        cursor: pointer;

        border-radius: 50%;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          width: 100%;
          height: 100%;
          background: ${fillColor};
          transition: 0.5s ease-in-out;

          &:hover {
            background: ${hoverFillColor};
          }
        `}
      >
        <Image
          css={css`
            height: 33%;
          `}
          src={src}
        />
      </div>
    </div>
  </a>
)
export default GradientCircleLinkImage
