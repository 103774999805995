import React from "react"
import { css, keyframes } from "@emotion/core"

const Spinner = ({ size = 50, fillColor = "white", border = 7, progress }) => (
  <div
    css={css`
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
  >
    <div
      css={theme => css`
        animation: ${keyframes`
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }

          `} 1s infinite linear;

        position: absolute;
        border-radius: 50%;
        width: ${size}px;
        height: ${size}px;
        ${theme.gradients.blueSpinner(theme, fillColor)};
        padding: ${border}px;

        &:after {
          border-radius: 50%;
          content: "";
          display: block;
          position: relative;
          width: 100%;
          height: 100%;
          margin: 0 auto;
          background: ${fillColor};
        }
      `}
    />
    {progress && (
      <div
        css={theme =>
          css`
            z-index: 100;
            font-weight: ${theme.constants.light};
            font-size: ${theme.constants.mediumFontSize}px;
            color: ${theme.colors.darkBlue};
          `
        }
      >
        {Math.floor(progress)}%
      </div>
    )}
  </div>
)

export default Spinner
