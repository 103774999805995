export const pathNameToTitle = path => {
  if (path.includes("about")) {
    return "About"
  }
  if (path.includes("services")) {
    return "Services"
  }
  if (path.includes("projects")) {
    return "Projects"
  }
  if (path.includes("career")) {
    return "Career"
  }
  if (path.includes("contact")) {
    return "Contact"
  }
  return ""
}
