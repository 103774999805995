import React, { useState, useEffect } from "react"
import { css, keyframes } from "@emotion/core"

import RightArrow from "./RightArrow"

const Button = ({
  children,
  arrowVariant = "blue",
  hoverArrowVariant = "darkblue",
  className,
  onClick,
  centered,
  ...rest
}) => {
  const [variant, setVariant] = useState()
  const [hovered, setHovered] = useState()

  useEffect(() => {
    setVariant(arrowVariant)
  }, [arrowVariant])

  const moveCloser = keyframes`
      0% {
        transform: translateX(0px);
      }
      25% {
        transform: translateX(-5px);
      }
      50% {
        transform: translateX(0px);
      }
      75% {
        transform: translateX(5px);
      }
      100% {
        transform: translateX(0px);
      }

    `

  return (
    <button
      onMouseEnter={() => {
        setVariant(hoverArrowVariant)
        setHovered(true)
      }}
      onMouseLeave={() => {
        setVariant(arrowVariant)
        setHovered(false)
      }}
      onClick={onClick}
      className={className}
      css={theme => css`
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: ${centered ? "center" : "space-between"};
        height: 50px;
        background: transparent;
        cursor: pointer;

        color: ${theme.colors.blue};

        &:disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }

        @media (max-width: 600px) {
          font-size: ${theme.constants.smallButtonTextSize}px;
          height: 30px;
          padding: 0;
        }
      `}
      {...rest}
    >
      <div
        css={css`
          margin-left: 10px;
          ${centered && "margin-right: 30px;"}
        `}
      >
        {children.toUpperCase()}
      </div>
      <RightArrow
        variant={variant}
        css={css`
          margin-right: 8px;
          animation: ${hovered && moveCloser} 1s linear;
          animation-iteration-count: infinite;
        `}
      />
    </button>
  )
}

export default Button
