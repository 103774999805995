import { useEffect, useState } from "react"
import { isBrowser } from "../utils/isBrowser"

export const useWindowPosition = () => {
  const [position, setPosition] = useState()

  useEffect(() => {
    setPosition(isBrowser() && window.pageYOffset)
    const handleScroll = () => setPosition(isBrowser() && window.pageYOffset)
    if (isBrowser()) {
      window.addEventListener("scroll", handleScroll)
    }

    return () =>
      isBrowser() && window.removeEventListener("scroll", handleScroll)
  }, [])

  return position
}
