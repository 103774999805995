import React from "react"
import { css } from "@emotion/core"
import { CircleLinkImage } from "../common"

import FacebookLogo from "../../images/common/facebook.svg"
import LinkedinLogo from "../../images/common/linkedin.svg"
import InstagramLogo from "../../images/common/instagram.svg"

const FollowFooter = () => (
  <div
    css={css`
      display: flex;
      justify-content: space-between;
      width: 70%;

      @media (max-width: 1000px) {
        width: 50%;
        margin: 0 auto;
      }

      @media (max-width: 600px) {
        width: 60%;
      }

      @media (max-width: 360px) {
        width: 80%;
      }
    `}
  >
    <CircleLinkImage
      src={FacebookLogo}
      to="https://www.facebook.com/deverestteam/"
    />
    <CircleLinkImage
      src={LinkedinLogo}
      to="https://www.linkedin.com/company/deverest-development"
    />
    <CircleLinkImage
      src={InstagramLogo}
      to="https://www.instagram.com/deverestdevelopment"
    />
  </div>
)

export default FollowFooter
