import React from "react"
import Zoom from "react-reveal/Zoom"
import Reveal from "react-reveal/Reveal"
import Fade from "react-reveal/Fade"
import { useWindowWidth } from "../../hooks/useWindowWidth"

const Animate = ({ type, children, ...rest }) => {
  const width = useWindowWidth()

  if (width >= 880) {
    if (type === "zoom") {
      return <Zoom {...rest}>{children}</Zoom>
    }
    if (type === "reveal") {
      return <Reveal {...rest}>{children}</Reveal>
    }
    if (type === "fade") {
      return <Fade {...rest}>{children}</Fade>
    }
  }
  return children
}

export default Animate
