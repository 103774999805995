import React from "react"
import { css } from "@emotion/core"
import Image from "./Image"

const CircleLinkImage = ({ src, to, className }) => (
  <a href={to} target="_blank" rel="noreferrer noopener">
    <div
      className={className}
      css={theme => css`
        width: 50px;
        height: 50px;
        background: ${theme.colors.transparentWhite};
        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;

        border-radius: 25px;
        border: 2px solid ${theme.colors.white};
        transition: 0.5s ease-in-out;

        &:hover {
          background: transparent;
        }
      `}
    >
      <Image
        css={css`
          height: 33%;
        `}
        src={src}
      />
    </div>
  </a>
)

export default CircleLinkImage
