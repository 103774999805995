import React from "react"
import { css } from "@emotion/core"

import { Image, Link } from "./common"

import DeverestLogo from "../images/common/logo_white.svg"

const linkStyle = theme => css`
  text-decoration: none;
  color: ${theme.colors.white};
  font-weight: ${theme.constants.extraLight};

  @media (max-width: 880px) {
    padding: 10px 0;
    width: 100%;
    text-align: center;
  }
`

const Footer = () => (
  <footer
    css={theme => css`
      height: ${theme.constants.footerHeight}px;
      padding: ${theme.constants.pageMargin};
      ${theme.gradients.blue(theme)};

      font-size: ${theme.constants.footerTextSize}px;
      color: ${theme.colors.white};

      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 1200px) {
        padding: ${theme.constants.mediumpageMargin};
      }

      @media (max-width: 880px) {
        padding: ${theme.constants.smallpageMargin};
        flex-direction: column;
        padding-top: 60px;
        padding-bottom: 60px;
        height: max-content;
      }
    `}
  >
    <Link
      to="/"
      css={css`
        height: 30px;
        width: 170px;

        @media (max-width: 880px) {
          height: 40px;
          width: 100%;
          margin-bottom: 40px;
        }
      `}
    >
      <Image
        src={DeverestLogo}
        css={css`
          height: 100%;
          width: 100%;
        `}
      />
    </Link>
    <div
      css={theme => css`
        flex: 1;
        margin-left: 90px;
        font-weight: ${theme.constants.demi};

        @media (max-width: 880px) {
          flex: 0;
          margin-left: 0px;
          order: 2;
          margin-top: 30px;
        }
      `}
    >
      Copyright © {new Date().getFullYear()} Deverest
    </div>
    <div
      css={css`
        width: 360px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 880px) {
          flex-direction: column;
          width: 100%;
        }
      `}
    >
      <Link css={linkStyle} to="/about">
        About
      </Link>
      <Link css={linkStyle} to="/services">
        Services
      </Link>
      <Link css={linkStyle} to="/projects">
        Projects
      </Link>
      <Link css={linkStyle} to="/career">
        Career
      </Link>
      <Link css={linkStyle} to="/contact">
        Contact
      </Link>
    </div>
  </footer>
)

export default Footer
