import React from "react"
import { css } from "@emotion/core"
import { useWindowWidth } from "../../hooks/useWindowWidth"

import GradientLinkButton from "./GradientLinkButton"

const AboutBox = () => {
  const width = useWindowWidth()

  return (
    <div
      css={theme => css`
        background: ${theme.colors.veryDarkgray};
        background-size: contain;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @media (max-width: 1000px) {
          padding: 50px;
        }

        @media (max-width: 880px) {
          padding: 50px 20px;
        }
      `}
    >
      <div
        css={theme => css`
          font-size: ${theme.constants.contactBoxTitleSize}px;
          font-weight: ${theme.constants.demi};
          color: ${theme.colors.white};
          margin-bottom: 30px;
          text-align: center;
        `}
      >
        We can offer you a solution.
      </div>
      <div
        css={theme => css`
          font-size: ${theme.constants.aboutBoxDescriptionSize}px;
          font-weight: ${theme.constants.light};
          color: ${theme.colors.darkGray};
          text-align: center;

          margin: 0 128px;
          margin-bottom: 16px;

          @media (max-width: 880px) {
            width: 100%;
          }
        `}
      >
        We will quickly develop your software, all you have to worry about is
        telling us what you need.
      </div>
      <GradientLinkButton
        centered={width <= 880}
        css={css`
          width: 300px;

          @media (max-width: 880px) {
            width: 100%;
          }
        `}
        to="/contact"
      >
        check our projects
      </GradientLinkButton>
    </div>
  )
}

export default AboutBox
