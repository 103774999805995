import React from "react"
import { css } from "@emotion/core"
import Image from "./Image"
import Animate from "./Animate"

import { useWindowWidth } from "../../hooks/useWindowWidth"

import ArrowImage from "../../images/common/large_arrow.svg"

const Arrow = ({ flip, top, left, right, bottom }) => {
  const width = useWindowWidth()

  if (width <= 1000) {
    return <></>
  }

  return (
    <div
      css={css`
        user-select: none;
        position: absolute;
        right: ${right}px;
        top: ${top}px;
        bottom: ${bottom}px;
        left: ${left}px;
        z-index: 90;
        ${flip && "transform: rotateZ(180deg)"};
      `}
    >
      <Animate type="reveal" cascade right distance="50px">
        <Image src={ArrowImage} />
      </Animate>
    </div>
  )
}
export default Arrow
