import React from "react"
import { css } from "@emotion/core"

import ContactFooter from "./infocolumns/ContactFooter"
import FollowFooter from "./infocolumns/FollowFooter"
import MeetFooter from "./infocolumns/MeetFooter"

import InfoColumn from "./InfoColumn"

const Info = ({ children }) => (
  <div
    css={css`
      position: relative;
      padding-top: 200px;
      margin-top: 50px;

      @media (max-width: 1000px) {
        position: initial;
        padding-top: 0px;
        margin-top: 0px;
      }
    `}
  >
    <div
      css={css`
        width: 70%;
        height: 300px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%);

        @media (max-width: 1000px) {
          transform: translate(0);
          position: initial;
          height: max-content;
          width: 100%;
        }
      `}
    >
      {children}
    </div>
    <div
      css={theme => css`
        background: ${theme.colors.veryDarkgray};
        padding: ${theme.constants.pageMargin};

        @media (max-width: 1350px) {
          padding-right: 50px;
          padding-left: 50px;
        }

        @media (max-width: 1200px) {
          grid-column-gap: 50px;
        }

        @media (max-width: 1000px) {
          padding-top: 50px;
        }

        padding-top: 200px;
        padding-bottom: 94px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
        grid-auto-rows: auto;
        grid-column-gap: 140px;
        grid-row-gap: 0px;
        width: 100%;

        @media (max-width: 1000px) {
          padding-bottom: 20px;
          grid-template-columns: 1fr;
          text-align: center;
        }

        @media (max-width: 600px) {
          padding-left: 20px;
          padding-right: 20px;
        }
      `}
    >
      <InfoColumn
        title="Contact us"
        detail="Do you have a new idea or you want to start a new project? Book a call with an expert!"
        footer={ContactFooter}
      />
      <InfoColumn
        title="Meet us"
        detail="Let’s meet! Tell us what you and your company need and we will find the best solution for you."
        footer={MeetFooter}
      />
      <InfoColumn
        title="Follow us"
        detail="Follow us on social media! Get to know us and our projects on Facebook, Instagram and LinkedIn!"
        footer={FollowFooter}
      />
    </div>
  </div>
)

export default Info
