import React from "react"
import { css } from "@emotion/core"

const MeetFooter = () => (
  <a
    target="_blank"
    rel="noreferrer noopener"
    href="https://goo.gl/maps/EKSESriBKStFuGoT8"
    css={theme => css`
      color: ${theme.colors.blue};
      text-decoration: none;
      line-height: 26px;
      transition: 0.5s;

      &:hover {
        color: ${theme.colors.darkBlue};
      }
    `}
  >
    1052, Aranykéz utca 2, Budapest, Hungary
  </a>
)

export default MeetFooter
