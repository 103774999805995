import React from "react"
import { css } from "@emotion/core"

import Image from "./Image"

import CloseButtonImage from "../../images/common/close_button.svg"

const CloseButton = ({ onClick, className, ...rest }) => (
  <button
    onClick={onClick}
    className={className}
    css={theme => css`
      text-decoration: none;
      display: flex;
      align-items: center;
      height: 40px;
      width: 40px;
      padding: 10px 0;
      background: transparent;
      cursor: pointer;
      z-index: 1000;

      color: ${theme.colors.blue};
    `}
    {...rest}
  >
    <Image
      src={CloseButtonImage}
      alt="close"
      css={css`
        height: 100%;
        width: 100%;
      `}
    />
  </button>
)

export default CloseButton
