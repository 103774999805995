import React from "react"
import { css } from "@emotion/core"

const InfoColumn = ({ title, detail, footer: Footer, dark, smallDetail }) => (
  <div
    css={css`
      margin-bottom: 50px;
    `}
  >
    <div
      css={theme => css`
        color: ${dark ? theme.colors.black : theme.colors.white};
        font-size: ${theme.constants.infoTitleTextSize}px;
        font-weight: ${theme.constants.demi};
        margin-bottom: 35px;
        height: ${smallDetail && "70px"};

        @media (max-width: 600px) {
          font-size: ${theme.constants.smallFontSize}px;
          margin-bottom: 10px;
        }

        @media (max-width: 600px) {
          height: max-content;
        }
      `}
    >
      {title}
    </div>
    <div
      css={theme => css`
        color: ${dark ? theme.colors.black : theme.colors.darkGray};
        font-size: ${smallDetail
          ? theme.constants.extraSmallFontSize
          : theme.constants.infoDescriptionTextSize}px;

        min-height: 100px;
        ${smallDetail &&
          `
          font-weight: ${theme.constants.light};
          line-height: 1.5;
        `};

        @media (max-width: 1000px) {
          margin-bottom: 20px;
          min-height: inherit;
        }

        @media (max-width: 600px) {
          font-size: ${theme.constants.extraSmallFontSize}px;
          line-height: 1.7;
          font-weight: ${theme.constants.light};
        }
      `}
    >
      {detail}
    </div>
    {Footer && <Footer />}
  </div>
)

export default InfoColumn
