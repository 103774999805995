import React, { useState } from "react"
import { css, keyframes } from "@emotion/core"
import { isBrowser } from "../../utils/isBrowser"
import Image from "./Image"
import Option from "./Option"

import ArrowImage from "../../images/common/select_arrow.png"
import DropdownArrowImage from "../../images/common/dropdown_arrow.svg"

const Dropdown = ({
  name,
  placeholder,
  type,
  value,
  onChange,
  options,
  light,
  ...rest
}) => {
  const [active, setActive] = useState(false)
  const [focus, setFocus] = useState(false)
  const [shouldMoveDown, setShouldMoveDown] = useState(false)

  const moveUp = keyframes`
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(-30px);
    }
  `
  const moveDown = keyframes`
    from {
      transform: translateY(-30px);
    }

    to {
      transform: translateY(0px);
    }
  `

  const moveIn = keyframes`
    0% {
      transform: translateY(-25%) scale(0.5) translateX(-10px);
    }
    50% {
      transform: translateY(-25%) scale(0.5) translateX(0px);
    }
  `

  return (
    <div
      css={css`
        position: relative;
        margin-bottom: 50px;

        &:hover {
          & > img {
            opacity: 1;
            animation: ${moveIn} 0.5s linear;
            animation-iteration-count: 1;
          }

          & > span {
            opacity: 1;
          }

          &:after {
            opacity: 1;
          }
        }

        &:after {
          content: url(${DropdownArrowImage});
          opacity: 0.4;
          position: absolute;
          top: 0;
          right: 10px;
          transform: ${active
            ? "scaleY(-1) translateY(-50%)"
            : "translateY(25%)"};
          transform-origin: center;
        }
      `}
      onFocus={() => {
        setActive(true)
        setFocus(true)
        setShouldMoveDown(false)
      }}
      onBlur={() => {
        if (value === "") {
          setShouldMoveDown(true)
        }
        setFocus(false)
      }}
    >
      <Image
        src={ArrowImage}
        css={css`
          opacity: ${active ? 1 : 0};
          position: absolute;
          top: 0px;
          left: -36px;
          transform: translateY(-25%) scale(0.5);
          transition: 0.5s;
        `}
      />
      <span
        css={theme => css`
          font-size: ${theme.constants.xxxSmallFontSize}px;
          font-weight: ${theme.constants.demi};
          ${light && `color: ${theme.colors.darkGray};`}
          position: absolute;
          padding-top: 10px;
          height: 35px;
          padding-left: 20px;
          transform-origin: center center;
          width: 100%;
          top: 0;
          left: 0;
          opacity: 0.4;
          transition: 0.5s;

          &:hover {
            opacity: 1;
          }

          animation: ${(active || value) && moveUp} 0.5s ease,
            ${shouldMoveDown && !value && moveDown} 0.5s ease;
          animation-fill-mode: forwards;
          animation-iteration-count: 1;
        `}
        onAnimationStart={() => {
          if (!focus) {
            setActive(false)
          }
        }}
      >
        {placeholder.toUpperCase()}
      </span>
      <select
        id={name}
        type={type}
        value={value}
        onChange={onChange}
        css={theme => css`
          appearance: none;
          outline: 0;
          width: 100%;
          height: 35px;
          border: 0px;
          color: ${light ? theme.colors.darkGray : theme.colors.white};
          background: transparent;
          background-image: linear-gradient(
            45deg,
            ${theme.colors.darkGray} 0%,
            ${theme.colors.darkGray} 100%
          );
          background-repeat: no-repeat;
          background-position-y: bottom;
          background-size: 100% 2px;
          font-size: ${theme.constants.xxxSmallFontSize}px;
          font-weight: ${theme.constants.demi};

          opacity: ${value ? "1" : "0.4"};
          padding-left: 20px;
          transition: 0.5s;

          &:focus {
            opacity: 1;
            ${theme.gradients.blue(theme)};
            background-repeat: no-repeat;
            background-position-y: bottom;
            background-size: 100% 2px;
          }
        `}
        {...rest}
      >
        <option
          css={css`
            display: none;
          `}
          hidden
          value=""
          disabled="disabled"
        />
        {options.map(option => (
          <option
            css={css`
              display: none;
            `}
            value={option}
            key={option}
          >
            {option}
          </option>
        ))}
      </select>
      {isBrowser() && !window.navigator.vendor.includes("Apple") && (
        <div
          css={theme => css`
            display: ${active ? "block" : "none"};
            font-size: ${theme.constants.extraSmallFontSize}px;
            position: absolute;
            background: ${light
              ? theme.colors.dropdownLightBackground
              : theme.colors.dropdownDarkBackground};
            width: 102%;
            padding: 27px;
            z-index: 30;
          `}
        >
          {options.map(option => (
            <Option
              light={light}
              onChange={onChange}
              value={option}
              key={option}
              setActive={setActive}
            />
          ))}
        </div>
      )}
    </div>
  )
}
export default Dropdown
