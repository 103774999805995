import React, { useState, useEffect } from "react"
import { css, keyframes } from "@emotion/core"
import Link from "./Link"

import RightArrow from "./RightArrow"

const LinkButton = ({
  to,
  children,
  arrowVariant = "blue",
  hoverArrowVariant = "darkblue",
  className,
  centered,
  pure,
}) => {
  const [variant, setVariant] = useState()
  const [hovered, setHovered] = useState()

  useEffect(() => {
    setVariant(arrowVariant)
  }, [arrowVariant])

  const moveCloser = keyframes`
      0% {
        transform: translateX(0px);
      }
      25% {
        transform: translateX(-5px);
      }
      50% {
        transform: translateX(0px);
      }
      75% {
        transform: translateX(5px);
      }
      100% {
        transform: translateX(0px);
      }
    `

  return (
    <Link
      onMouseEnter={() => {
        setVariant(hoverArrowVariant)
        setHovered(true)
      }}
      onMouseLeave={() => {
        setVariant(arrowVariant)
        setHovered(false)
      }}
      className={className}
      to={to}
      css={theme => css`
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: ${centered ? "center" : "space-between"};
        height: 50px;

        color: ${theme.colors.blue};

        &:hover {
          color: ${theme.colors.darkBlue};
        }

        @media (max-width: 600px) {
          font-size: ${theme.constants.smallButtonTextSize}px;
          height: 30px;
        }
      `}
    >
      <div
        css={css`
          margin-left: ${pure ? 0 : 10}px;
          ${centered && "margin-right: 30px;"}
        `}
      >
        {children.toUpperCase()}
      </div>
      <RightArrow
        variant={variant}
        css={css`
          margin-right: 8px;
          animation: ${hovered && moveCloser} 1s linear;
          animation-iteration-count: infinite;
        `}
      />
    </Link>
  )
}

export default LinkButton
