import React, { useState } from "react"
import { css, keyframes } from "@emotion/core"
import Link from "./Link"

import { isBrowser } from "../../utils/isBrowser"

import ArrowImage from "../../images/common/select_arrow.png"

const HeaderLink = ({ to, className, hero, children, ...rest }) => {
  const [hovered, setHovered] = useState(false)
  const active = isBrowser() && window.location.pathname.includes(to)

  const moveIn = keyframes`
      0% {
        transform: scale(0.5) translateX(-10px);
      }
      50% {
        transform: scale(0.5) translateX(0px);
      }
    `

  const moveOut = keyframes`
      0% {
        transform: scale(0.5) translateX(0px);
      }
      50% {
        transform: scale(0.5) translateX(-10px);
      }
    `

  return (
    <Link
      to={to}
      className={className}
      {...rest}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      css={theme => css`
        text-decoration: none;
        color: ${hero ? theme.colors.white : theme.colors.black};
        display: flex;
        align-items: center;
        font-size: ${theme.constants.headerTextSize}px;
        font-weight: ${active ? theme.constants.bold : theme.constants.light};

        &:before {
          content: url(${ArrowImage});
          transform: scale(0.5);
          width: 36px;
          opacity: 0;
          transition: 0.5s;
          opacity: ${active ? 1 : 0};
          animation: ${hovered ? moveIn : moveOut} 0.5s linear;
          animation-iteration-count: 1;
        }

        &:hover {
          &:before {
            opacity: 1;
            content: url(${ArrowImage});
            transform: scale(0.5);
          }
        }
      `}
    >
      {children}
    </Link>
  )
}

export default HeaderLink
