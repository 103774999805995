import React from "react"
import { css } from "@emotion/core"

import { useWindowWidth } from "../../hooks/useWindowWidth"

import GradientLinkButton from "./GradientLinkButton"

import MoreProjectBackground from "../../images/common/more_projects.jpg"

const ProjectsBox = () => {
  const width = useWindowWidth()

  return (
    <div
      css={css`
        height: 300px;
        margin: 200px 60px;
        background: url(${MoreProjectBackground});
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @media (max-width: 1000px) {
          margin: 0px;
        }
      `}
    >
      <div
        css={theme => css`
          width: 40%;
          text-align: center;
          font-size: ${theme.constants.contactBoxTitleSize}px;
          font-weight: ${theme.constants.light};
          color: ${theme.colors.white};
          margin-bottom: 30px;

          @media (max-width: 880px) {
            width: 90%;
          }
        `}
      >
        Would you like to see more projects?
      </div>
      <GradientLinkButton
        centered={width <= 880}
        css={css`
          width: 300px;

          @media (max-width: 880px) {
            width: 100%;
          }
        `}
        to="/projects"
      >
        see our projects
      </GradientLinkButton>
    </div>
  )
}
export default ProjectsBox
