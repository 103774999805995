import React from "react"
import { Link as GatsbyLink } from "gatsby"

const Link = ({ children, to, className, ...rest }) => (
  <GatsbyLink className={className} to={to} {...rest}>
    {children}
  </GatsbyLink>
)

export default Link
