import React from "react"
import { css } from "@emotion/core"

const ContactFooter = () => (
  <div>
    <p
      css={css`
        padding-bottom: 10px;
      `}
    >
      <a
        css={theme => css`
          color: ${theme.colors.blue};
          text-decoration: none;
          transition: 0.5s;

          &:hover {
            color: ${theme.colors.darkBlue};
          }
        `}
        href="mailto:hello@deverest.io"
      >
        hello@deverest.io
      </a>
    </p>
    <p>
      <a
        css={theme => css`
          color: ${theme.colors.blue};
          text-decoration: none;
          transition: 0.5s;

          &:hover {
            color: ${theme.colors.darkBlue};
          }
        `}
        href="tel:+36304445762"
      >
        +36 30 444 5762
      </a>
    </p>
  </div>
)

export default ContactFooter
